<template>
  <div class="dimm">
    <div class="confirmBox">
      <div class="textBox" v-html="confirmText"></div>
      <div class="buttonBox">
        <button class="cancelBtn" @click="onCLickFalse">취소</button>
        <button @click="onClickTrue">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed:{
    confirmText() {
      return this.$store.state.confirmText.split('\n').join('<br>');
    }
  },
  methods: {
    onClickTrue() {
      if (typeof this.$store.state.confirmClickEvent === 'function') {
        this.$store.state.confirmClickEvent();
      }
      this.$store.commit('controlAscentRatePopup', false)
      this.close();
    },
    onCLickFalse() {
      this.close();
    },
    close() {
      this.$store.commit('resetConfirm');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/pruConfirm'
</style>
