import tokenUtils from "./tokenUtils";

export default {
  TokenGuard : (to,from,next) => {
    tokenUtils.verification().then(() => {
      next()
    }).catch(error => {
      alert(error)
      next({name: 'wrongApproach'})
    })
  }
}
