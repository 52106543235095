const KEYS = {
  token: 'token'
}

const get = (key, defaultValue = '') => {
  let result = window.sessionStorage.getItem(key)
  return result === null ? defaultValue : result
}

const save = (key, value) => {
  window.sessionStorage.setItem(key, value)
}

const clear = () => {
  window.sessionStorage.clear()
}

const getToken = () => {
  return get(KEYS.token, null)
}

const saveToken = (value) => {
  save(KEYS.token, value)
}

export default { get, save, clear, getToken, saveToken }