import Vue from 'vue'
import VueRouter from 'vue-router'
import MsgPage from '@/components/msgPage.vue'
import RouterGuard from '@/utils/RouterGuard.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/pages/Login.vue'),
  },
  {
    path: '/auth',
    name: 'userCheck',
    component: () => import('@/pages/userCheck.vue')
  },
  {
    path: '/base',
    name: 'base',
    component: () => import('@/pages/Base.vue'),
    children: [
      {
        path: '/inheritForm',
        name: 'inheritForm',
        component: () => import('@/pages/InheritanceInputForm.vue'),
        beforeEnter: (to,from,next) => {
          RouterGuard.TokenGuard(to,from,next);
        }
      },
      {
        path: '/reportPage',
        name: 'reportPage',
        component: () => import('@/pages/ReportPage.vue'),
        beforeEnter: (to,from,next) => {
          RouterGuard.TokenGuard(to,from,next);
        }
      }
    ]
  },
  {
    path: '/error',
    name: 'tokenInvalid',
    component: MsgPage,
    meta: { errMsg: '유효하지 않은 로그인 입니다.'}
  },
  {
    path: '/error',
    name: 'whatHappened',
    component: MsgPage,
    meta: { errMsg: '알 수 없는 오류가 발생했습니다. 관리자에 문의하세요.'}
  },
  {
    path: '*',
    name: 'wrongApproach',
    component: MsgPage,
    meta: { errMsg: '잘못된 경로이거나 비정상적 접근입니다.'}
  },
  {
    path: '/reportEnd',
    name: 'reportEnd',
    component: MsgPage,
    meta: { errMsg: '상속 진단 리포트가 종료되었습니다.'}
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
