export default {
  nowAge: (data) => {
    const age = typeof data === 'number' ? data : 0
    if (0 <= age && age <= 100) {
      return age
    } else {
      throw new Error('나이는 0 이상 100 이하입니다.')
    }
  },
  spouseAge: (data) => {
    const age = typeof data === 'number' ? data : 0
    if (0 <= age && age <= 100) {
      return age
    } else {
      new Error('배우자 나이는 0 이상 100 이하입니다.')
    }
    return 0 <= age && age <= 100
  },
  additionalInheritorCount: (data) => {
    const count = typeof data === 'number' ? data : 0
    if (0 <= count && count <= 5) {
      return count
    } else {
      new Error('추가상속자 수는 0 이상 5 이하입니다.')
    }
  },
  amount: (data) => {
    const amount = typeof data === 'number' ? data : 0
    if (0 <= amount && amount <= 100000000) {
      return amount
    } else {
      new Error('금액은 0 이상 100,000,000 이하입니다.')
    }
  },
  ascentRateP: (data) => {
    const ascentRate = typeof data === 'number' ? data : 0
    if (0 <= ascentRate && ascentRate <= 10) {
      return ascentRate
    } else {
      throw new Error('자산 증가율은 0 이상 10 이하입니다.')
    }
  },
  yearsLater: (yearsData, age) => {
    const years = typeof yearsData === 'number' ? yearsData : 0
    const maxYears = years + age
    if (10 <= years && maxYears <= 100) {
      return years
    } else {
      throw new Error(`상속 시점은 10 이상이거나 ${100 - maxYears} 이하입니다.`)
    }
  },
  totalShareOfInheritance: (data) => {
    const totalShareOfInheritance = typeof data === 'number' ? data : 0
    if (totalShareOfInheritance === 100) {
      return totalShareOfInheritance
    } else {
      throw new Error('상속 비율의 총 합은 100이어야 합니다.')
    }
  }
}
